<template>
    <main class="main-wrapper">
        <div class="axil-dashboard-area axil-section-gap">
            <div class="container">
                <div class="axil-dashboard-warp">
                    <div class="axil-dashboard-author">
                        <div class="media">
                            <div class="thumbnail">
                                <img
                                    src="/ragavan.png"
                                    style="max-height: 50px; max-width: 50px"
                                    alt="Hello"
                                />
                            </div>
                            <div class="media-body" v-if="form && form.id">
                                <h5 class="title mb-0">
                                    {{ $t("Hello") }} {{ form.name }}
                                </h5>
                                <span
                                    class="joining-date"
                                    v-if="form && form.credit"
                                    >{{ $t("Elérhető kreditek:") }}
                                    {{ form.credit_formatted }}</span
                                >
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xl-3 col-md-4">
                            <aside class="axil-dashboard-aside">
                                <nav class="axil-dashboard-nav">
                                    <div class="nav nav-tabs" role="tablist">
                                        <a
                                            class="nav-item nav-link active"
                                            data-bs-toggle="tab"
                                            href="#nav-dashboard"
                                            role="tab"
                                            aria-selected="true"
                                            ><i class="fas fa-th-large"></i
                                            >{{ $t("Kezdőlap") }}</a
                                        >
                                        <a
                                            class="nav-item nav-link"
                                            data-bs-toggle="tab"
                                            href="#nav-orders"
                                            role="tab"
                                            aria-selected="false"
                                            ><i
                                                class="fas fa-shopping-basket"
                                            ></i
                                            >{{ $t("Megrendelések") }}</a
                                        >
                                        <a
                                            class="nav-item nav-link"
                                            data-bs-toggle="tab"
                                            href="#nav-address"
                                            role="tab"
                                            aria-selected="false"
                                            ><i class="fas fa-home"></i
                                            >{{ $t("Profil adatok") }}</a
                                        >
                                        <a
                                            class="nav-item nav-link"
                                            data-bs-toggle="tab"
                                            href="#nav-account"
                                            role="tab"
                                            aria-selected="false"
                                            ><i class="fas fa-user"></i
                                            >{{ $t("Jelszó módosítás") }}</a
                                        >
                                        <a
                                            class="nav-item nav-link"
                                            href="#"
                                            v-on:click.prevent="logout"
                                            ><i class="fal fa-sign-out"></i
                                            >{{ $t("Kijelentkezés") }}</a
                                        >
                                    </div>
                                </nav>
                            </aside>
                        </div>
                        <div class="col-xl-9 col-md-8">
                            <div class="tab-content" v-if="form && form.id">
                                <div
                                    class="tab-pane fade show active"
                                    id="nav-dashboard"
                                    role="tabpanel"
                                >
                                    <div class="axil-dashboard-overview">
                                        <div class="welcome-text">
                                            {{ $t("Hello") }}
                                            {{ form.name }} ({{ $t("Nem") }}
                                            <span
                                                >{{ form.name }}
                                                {{ $t("vagy") }}?</span
                                            >
                                            <a
                                                href="#"
                                                v-on:click.prevent="logout"
                                                >{{ $t("Kijelentkezés") }}</a
                                            >)
                                        </div>
                                        <p>
                                            {{
                                                $t(
                                                    "A fiókodban meg tudod tekinteni a korábbi rendeléseidet, megváltoztathatod a címedet, valamint a jelszavadat is."
                                                )
                                            }}
                                        </p>
                                    </div>
                                </div>
                                <div
                                    class="tab-pane fade"
                                    id="nav-orders"
                                    role="tabpanel"
                                >
                                    <div class="axil-dashboard-order">
                                        <div class="table-responsive">
                                            <table class="table">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">
                                                            {{
                                                                $t(
                                                                    "Rendelés azonosító"
                                                                )
                                                            }}
                                                        </th>
                                                        <th scope="col">
                                                            {{ $t("Dátum") }}
                                                        </th>
                                                        <th scope="col">
                                                            {{ $t("Státusz") }}
                                                        </th>
                                                        <th scope="col">
                                                            {{ $t("Összeg") }}
                                                        </th>
                                                        <th scope="col">
                                                            {{
                                                                $t("Műveletek")
                                                            }}
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <template
                                                        v-for="(item,
                                                        index) in orders.data"
                                                    >
                                                        <tr
                                                            v-bind:key="
                                                                'order-' + index
                                                            "
                                                        >
                                                            <th scope="row">
                                                                #{{ item.id }}
                                                            </th>
                                                            <td>
                                                                {{
                                                                    item.created_at
                                                                }}
                                                            </td>
                                                            <td>
                                                                {{
                                                                    getStatus(
                                                                        item.status
                                                                    )
                                                                }}
                                                            </td>
                                                            <td>
                                                                {{
                                                                    item.total_price
                                                                }}
                                                                /
                                                                {{
                                                                    getCardNumbers(
                                                                        item.items
                                                                    )
                                                                }}
                                                                db
                                                            </td>
                                                            <td>
                                                                <a
                                                                    style="cursor: pointer"
                                                                    v-b-toggle="
                                                                        'accordion-' +
                                                                            index
                                                                    "
                                                                    class="axil-btn view-btn"
                                                                    >{{
                                                                        $t(
                                                                            "Megtekintés"
                                                                        )
                                                                    }}</a
                                                                >
                                                            </td>
                                                        </tr>
                                                        <b-collapse
                                                            tag="tr"
                                                            v-bind:key="
                                                                'item-acc-' +
                                                                    index
                                                            "
                                                            :id="
                                                                'accordion-' +
                                                                    index
                                                            "
                                                            accordion="my-accordion"
                                                            role="tabpanel"
                                                        >
                                                            <td colspan="4">
                                                                <b-card-body>
                                                                    <table
                                                                        class="table"
                                                                    >
                                                                        <tr
                                                                            v-for="(item2,
                                                                            index2) in item.items"
                                                                            v-bind:key="
                                                                                'order-items-' +
                                                                                    index2
                                                                            "
                                                                        >
                                                                            <td>
                                                                                {{
                                                                                    item2
                                                                                        .sell
                                                                                        .card
                                                                                        .name
                                                                                }}
                                                                                <br />
                                                                                {{
                                                                                    item2.sell.card.set.toUpperCase()
                                                                                }}
                                                                                /
                                                                                {{
                                                                                    item2
                                                                                        .sell
                                                                                        .card
                                                                                        .collector_number
                                                                                }}
                                                                            </td>
                                                                            <td
                                                                                v-if="
                                                                                    item2
                                                                                        .sell
                                                                                        .is_foil
                                                                                "
                                                                            >
                                                                                FOIL
                                                                            </td>
                                                                            <td
                                                                                v-else
                                                                            >
                                                                                NEM
                                                                                FOIL
                                                                            </td>
                                                                            <td>
                                                                                {{
                                                                                    item2.quantity
                                                                                }}
                                                                                db
                                                                            </td>
                                                                            <td>
                                                                                {{
                                                                                    item2.price_formatted
                                                                                }}
                                                                            </td>
                                                                        </tr>
                                                                    </table>
                                                                </b-card-body>
                                                            </td>
                                                        </b-collapse>
                                                    </template>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="text-center pt--20">
                                            <a
                                                href="#"
                                                v-on:click.prevent="
                                                    loadMoreData
                                                "
                                                v-if="showLoadMore"
                                                class="axil-btn btn-bg-lighter btn-load-more"
                                                >{{
                                                    $t("Továbbiak betöltése")
                                                }}</a
                                            >
                                        </div>
                                    </div>
                                </div>
                                <div
                                    class="tab-pane fade"
                                    id="nav-address"
                                    role="tabpanel"
                                >
                                    <div
                                        class="axil-dashboard-address"
                                        v-if="form && form.shipping"
                                    >
                                        <form
                                            class="account-details-form"
                                            v-on:submit.prevent="onSubmit"
                                        >
                                            <div class="row">
                                                <div class="col-lg-12">
                                                    <div class="form-group">
                                                        <label>{{
                                                            $t("Név")
                                                        }}</label>
                                                        <input
                                                            type="text"
                                                            class="form-control"
                                                            placeholder="Annie"
                                                            v-model="form.name"
                                                        />
                                                    </div>
                                                </div>
                                                <div class="col-lg-12">
                                                    <div class="form-group">
                                                        <label
                                                            style="z-index: unset !important;"
                                                            >{{
                                                                $t("E-mail")
                                                            }}</label
                                                        >
                                                        <input
                                                            type="email"
                                                            class="form-control"
                                                            placeholder="annie@example.com"
                                                            v-model="form.email"
                                                        />
                                                    </div>
                                                </div>
                                                <div class="col-lg-12">
                                                    <div class="form-group">
                                                        <label
                                                            style="top: -22px !important;"
                                                            >{{
                                                                $t(
                                                                    "Telefonszám"
                                                                )
                                                            }}</label
                                                        >
                                                        <VuePhoneNumberInput
                                                            v-model="phone"
                                                            :no-example="true"
                                                            default-country-code="HU"
                                                            v-on:update="
                                                                setPhoneUpdate
                                                            "
                                                            :preferred-countries="[
                                                                'HU',
                                                                'DE',
                                                                'SK',
                                                                'FR'
                                                            ]"
                                                        />
                                                        <!--<input
                                                            type="tel"
                                                            class="form-control"
                                                            placeholder="+36301231234"
                                                            v-model="
                                                                form.shipping
                                                                    .phone
                                                            "
                                                        />-->
                                                    </div>
                                                </div>
                                                <div class="col-lg-12">
                                                    <div class="form-group">
                                                        <label
                                                            style="z-index: unset !important;"
                                                            >{{
                                                                $t(
                                                                    "Irányítószám"
                                                                )
                                                            }}</label
                                                        >
                                                        <input
                                                            type="text"
                                                            class="form-control"
                                                            v-model="
                                                                form.shipping
                                                                    .zip
                                                            "
                                                        />
                                                    </div>
                                                </div>
                                                <div class="col-lg-12">
                                                    <div class="form-group">
                                                        <label
                                                            style="z-index: unset !important;"
                                                            >{{
                                                                $t("Város")
                                                            }}</label
                                                        >
                                                        <input
                                                            type="text"
                                                            class="form-control"
                                                            v-model="
                                                                form.shipping
                                                                    .city
                                                            "
                                                        />
                                                    </div>
                                                </div>
                                                <div class="col-lg-12">
                                                    <div class="form-group">
                                                        <label
                                                            style="z-index: unset !important;"
                                                            >{{
                                                                $t(
                                                                    "Utca / házszám"
                                                                )
                                                            }}</label
                                                        >
                                                        <input
                                                            type="text"
                                                            class="form-control"
                                                            v-model="
                                                                form.shipping
                                                                    .address
                                                            "
                                                        />
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <div
                                                        class="form-group mb--0"
                                                    >
                                                        <input
                                                            type="submit"
                                                            class="axil-btn"
                                                            :value="
                                                                $t('Mentés')
                                                            "
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <div
                                    class="tab-pane fade"
                                    id="nav-account"
                                    role="tabpanel"
                                >
                                    <div class="col-lg-9">
                                        <div class="axil-dashboard-account">
                                            <form
                                                class="account-details-form"
                                                v-on:submit.prevent="
                                                    onSubmitPass
                                                "
                                            >
                                                <div class="row">
                                                    <div class="col-12">
                                                        <h5 class="title">
                                                            {{
                                                                $t(
                                                                    "Jelszó módosítása"
                                                                )
                                                            }}
                                                        </h5>
                                                        <div class="form-group">
                                                            <label>{{
                                                                $t(
                                                                    "Régi jelszó"
                                                                )
                                                            }}</label>
                                                            <input
                                                                type="password"
                                                                class="form-control"
                                                                v-model="
                                                                    form.password_old
                                                                "
                                                                placeholder="********"
                                                            />
                                                        </div>
                                                        <div class="form-group">
                                                            <label>{{
                                                                $t("Új jelszó")
                                                            }}</label>
                                                            <input
                                                                type="password"
                                                                v-model="
                                                                    form.password
                                                                "
                                                                class="form-control"
                                                            />
                                                        </div>
                                                        <div class="form-group">
                                                            <label>{{
                                                                $t(
                                                                    "Új jelszó megerősítése"
                                                                )
                                                            }}</label>
                                                            <input
                                                                type="password"
                                                                v-model="
                                                                    form.password_confirm
                                                                "
                                                                class="form-control"
                                                            />
                                                        </div>
                                                        <div
                                                            class="form-group mb--0"
                                                        >
                                                            <button
                                                                type="submit"
                                                                class="axil-btn"
                                                            >
                                                                {{
                                                                    $t("Mentés")
                                                                }}
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- End My Account Area  -->
    </main>
</template>

<script>
import { mapGetters } from "vuex";
import ApiService from "@/core/services/api.service";
import VuePhoneNumberInput from "vue-phone-number-input";

import "vue-phone-number-input/dist/vue-phone-number-input.css";
export default {
    name: "Profile",
    data() {
        return {
            form: {},
            orders: [],
            showLoadMore: true,
            page: 1,
            phone: null
        };
    },
    components: { VuePhoneNumberInput },
    computed: {
        ...mapGetters(["currentUser"])
    },
    beforeMount() {
        const self = this;
        this.$root.$emit("showLoading", true);
        ApiService.get(`user/profile`)
            .then(resp => {
                this.form = resp.data.data;
                if (this.form && this.form.shipping) {
                    this.phone = this.form.shipping.phone;
                } else {
                    this.form.shipping = {
                        name: null,
                        country: null,
                        zip: null,
                        city: null,
                        address: null,
                        phone: null
                    };
                }
            })
            .catch(() => {
                this.$cookies.remove("user");
                this.$cookies.remove("id_token");
                document.location.href = "/";
            });

        ApiService.query(`/order/my-orders?page=${this.page}`)
            .then(resp => {
                this.orders = resp.data;
            })
            .finally(() => {
                self.$root.$emit("hideLoading", true);
            });
    },
    methods: {
        setPhoneUpdate(data) {
            this.form.shipping.phone = data.formattedNumber;
        },
        loadMoreData() {
            this.$root.$emit("showLoading", true);
            const self = this;
            self.page += 1;
            ApiService.query(`/order/my-orders?page=${self.page}`)
                .then(resp => {
                    for (const it in resp.data.data) {
                        self.orders.data.push(resp.data.data[it]);
                    }
                    if (resp.data.data.length < 1) {
                        self.showLoadMore = false;
                    }
                })
                .finally(() => {
                    self.$root.$emit("hideLoading", true);
                });
        },
        getCardNumbers(items) {
            let num = 0;
            for (const it in items) {
                num += items[it].quantity;
            }
            return num;
        },
        getStatus(status) {
            switch (status) {
                case 1:
                    return "Rendelés leadva";
                case 2:
                    return "Rendelés teljesítve";
                case 3:
                    return "Rendelés törölve";
                default:
                    return "Rendelés leadva";
            }
        },
        logout() {
            this.$cookies.remove("user");
            this.$cookies.remove("id_token");
            document.location.href = "/";
        },
        onSubmit() {
            ApiService.put(`user/update-profile`, this.form);
        },
        onSubmitPass() {
            if (this.form.password && this.form.password_again) {
                ApiService.put(`user/change-password`, this.form);
            }
        }
    }
};
</script>

<style>
.country-selector__country-flag {
    z-index: 40 !important;
}

.input-tel__input {
    height: 55px !important;
    min-height: 55px !important;
}

.country-selector__input {
    height: 55px !important;
    min-height: 55px !important;
}
</style>
